<template>
  <v-app-bar app elevation="4" height="70">
    <v-row align="center">
      <v-col cols="2" md="3">
        <v-row justify="start" align="center">
          <v-btn
            @click="
              $router.push({
                path: returnPath,
                query: $route.query,
              })
            "
            color="secondary"
            icon
            fab
            elevation="0"
            small
          >
            <v-icon color="accent">mdi-chevron-left</v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="8" md="6" class="py-0 px-1">
        <v-row justify="start" align="center">
          <v-col>
            <h1 class="text-h6 font-weight-regular text-center">
              {{ title }}
            </h1>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2" md="3"> </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    routeMeta() {
      if (typeof this.$route.meta === "function") {
        return this.$route.meta();
      }
      return this.$route.meta;
    },
    returnPath() {
      return this.routeMeta.appBar.returnPath;
    },
    title() {
      return this.routeMeta.appBar.title;
    },
  },
};
</script>
